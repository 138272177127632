import { toast } from "react-toastify";

type ErrorMessage = {
	[key: string]: string[];
};

type ErrorResponse = {
	response?: {
		data?: {
			message?:
				| string
				| ErrorMessage[]
				| { message: ErrorMessage[] }
				| string[];
		};
	};
	message?: string;
};

const renderErrors = (error: ErrorResponse | null) => {
	if (!error) return null;

	let errorMessages: string[] = [];

	if (error.response && error.response.data && error.response.data.message) {
		const dataMessage = error.response.data.message;

		if (Array.isArray(dataMessage)) {
			if (typeof dataMessage[0] === "string") {
				errorMessages = dataMessage as string[];
			} else {
				(dataMessage as ErrorMessage[]).forEach((errObj) => {
					Object.keys(errObj).forEach((field) => {
						errObj[field].forEach((msg) => {
							errorMessages.push(`${field}: ${msg}`);
						});
					});
				});
			}
		} else if (typeof dataMessage === "string") {
			errorMessages = [dataMessage];
		} else if (dataMessage.message) {
			(dataMessage.message as ErrorMessage[]).forEach((errObj) => {
				Object.keys(errObj).forEach((field) => {
					errObj[field].forEach((msg) => {
						errorMessages.push(`${field}: ${msg}`);
					});
				});
			});
		}
	} else if (error.message) {
		errorMessages = [error.message];
	}

	const showErrorToasts = () => {
		errorMessages.forEach((msg, index) => {
			setTimeout(() => {
				toast.error(msg);
			}, index * 1000);
		});
	};

	showErrorToasts();

	return null;
};

export default renderErrors;
