import React from "react";
import { useField } from "formik";
import {
	Button,
	FormikInput,
	Loader2,
	ProcessUploadFile,
	UploadedFile,
} from "../../../../components";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowLeft } from "iconsax-react";
import { uploadedFileContainerVariants } from "../../../../services/Animations";

interface Form2Props {
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
	acceptLoading?: boolean;
}

const GuarantorFormStep3: React.FC<Form2Props> = ({
	setActiveStep,
	acceptLoading,
}) => {
	const [field, meta, helpers] = useField("signature_document");
	const [nameField, ,] = useField("signature_documentName");

	const currentDate = new Date();

	// Calculate the date 22 years ago
	const maxDate = new Date(
		currentDate.getFullYear() - 22,
		currentDate.getMonth(),
		currentDate.getDate()
	);
	return (
		<>
			<div className="mb-7 flex w-full max-w-[640px] flex-col items-start justify-center space-y-7 ">
				<Button
					disabled={false}
					additionalClasses="text-[#07122F] bg-transparent w-auto leading text-lg flex items-center gap-4"
					onClick={() => setActiveStep(1)}
				>
					<ArrowLeft color="#07122F" />
					<span>Guarantor’s Form</span>
				</Button>
				<div className="flex w-full items-center justify-center">
					<div className="flex items-center font-bold">
						<div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#1A4393] text-white">
							1
						</div>
						<div className="h-px w-16 bg-[#1A4393]"></div>
						<div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#1A4393] text-white">
							2
						</div>
					</div>
				</div>
			</div>
			<div className="mb-2 inline h-full w-full max-w-[640px] flex-1 flex-col items-start justify-center space-y-6 overflow-y-auto pb-2 pr-2">
				<FormikInput
					label="Full Name"
					labelStyles="!text-[#596780] !font-normal"
					name="name"
					id="name"
					type="text"
					placeholder="Enter your name"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					required={true}
				/>
				<FormikInput
					label="Date of Birth"
					labelStyles="!text-[#596780] !font-normal"
					name="date_of_birth"
					id="date_of_birth"
					type="date"
					placeholder="Enter your date of birth"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					required={true}
					max={maxDate.toISOString().split("T")[0]}
				/>
				<FormikInput
					label="Occupation"
					labelStyles="!text-[#596780] !font-normal"
					name="occupation_type"
					id="occupation_type"
					type="text"
					placeholder="Enter occupation"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					required={true}
				/>
				<FormikInput
					label="Address"
					labelStyles="!text-[#596780] !font-normal"
					name="address"
					id="address"
					type="text"
					placeholder="Enter your address"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					required={true}
				/>
				<FormikInput
					label="Borrower Name"
					labelStyles="!text-[#596780] !font-normal"
					name="borrower_name"
					id="borrower_name"
					type="text"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					disabled
					required={false}
				/>
				<FormikInput
					label="Borrower Address"
					labelStyles="!text-[#596780] !font-normal"
					name="borrower_address"
					id="borrower_address"
					type="text"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					disabled
					required={false}
				/>
				<FormikInput
					label="Relationship with Borrower"
					labelStyles="!text-[#596780] !font-normal"
					name="relationship"
					id="relationship"
					type="text"
					placeholder="Enter relationship with Borrower"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					required={true}
				/>

				<div className="flex w-full flex-col">
					<div className="-mb-1 flex flex-col text-left">
						<h2 className="font-normal text-[#596780]">
							Upload Signature
							<span className="ml-1.5 text-red-700">*</span>
						</h2>
					</div>
					<ProcessUploadFile
						formats={["jpeg", "jpg", "png"]}
						maxSize={5 * 1024 * 1024}
						name="signature_document"
						localStorageFileName={"signature_document"}
					/>
					<AnimatePresence>
						{field.value && (
							<motion.div
								className="flex w-full flex-col gap-4"
								variants={uploadedFileContainerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<h4 className="w-full text-[#676767]">
									Uploaded file(s)
								</h4>
								<div className="flex flex-col gap-3">
									<UploadedFile
										title={nameField.value}
										deleteFile={() => {
											helpers.setValue(null);
										}}
									/>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
					{meta.touched && meta.error ? (
						<p className="mt-2 text-xs text-error-600">
							{meta.error}
						</p>
					) : null}
				</div>
				<FormikInput
					label="Date of Signature"
					labelStyles="!text-[#596780] !font-normal"
					name="date_of_signature"
					id="date_of_signature"
					type="text"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					disabled
					required={false}
				/>
				<FormikInput
					label={
						<>
							I, the undersigned, hereby acknowledge and agree
							that I have read and understood the terms of this
							Guarantee and Indemnity, which I am providing on
							behalf of the Borrower, and for the benefit of Hadi
							Africa Limited until such time as the Borrower’s
							obligations to Hadi Africa Limited ceases:
						</>
					}
					labelStyles="!text-[#C4C4C4] !font-normal !text-sm"
					name="terms"
					id="terms"
					type="checkbox"
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					containerStyle="-mt-6 col-span-2 relative"
					secondaryContainerStyle="!items-start"
					required={true}
				/>
			</div>
			<div className="mb-7 flex w-full max-w-[640px] flex-col items-start justify-center space-y-7 ">
				<Button
					disabled={acceptLoading}
					type="submit"
					additionalClasses="text-lg text-white font-medium bg-primary-800 mt-[14px] lg:mt-[27px] col-span-2"
				>
					{acceptLoading ? <Loader2 color="#061F6A" /> : "Submit"}
				</Button>
			</div>
		</>
	);
};

export default GuarantorFormStep3;
